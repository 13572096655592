<template>
  <div>
    <div class="text-left">
      <div class="card mt-2 border-0 bg-white shadow">
        <div class="card-body d-flex justify-space-between">
          <h3>Wallet Entries</h3>
          <div class="ml-auto">
            <h5>Wallet Balance <span class="font-weight-bold text-uppercase">${{wallet_balance || '0.0'}}</span> </h5>
            <button
              class="btn btn-primary ml-2"
              data-toggle="modal"
              data-target="#walletModal"
            >
              Topup Wallet
            </button>
            <!-- <h4 type="button" class="text-success btn-labeled">
              <strong>Balance ${{ wallet_balance.balance || 0 }}</strong>
            </h4> -->
          </div>
        </div>

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr class="text-left">
                <th>#</th>
                <th>Date</th>
                <th>Description</th>
                <th>Credit</th>
                <th>Debit</th>
              </tr>
            </thead>
            <tbody v-if="wallet_entries.length > 0">
              <tr
                v-for="(wallet, index) in wallet_entries"
                :key="index + 'ords'"
              >
                <td class="text-left">{{ index + 1 }}</td>
                <td class="text-left">
                  <!-- <router-link :to="{name: 'wallet', params: { id: wallet.id }}"></router-link>  -->
                  {{ wallet.create_stamp || "" }}
                </td>
                <td class="text-left">{{ wallet.description || "" }}</td>
                <td class="text-left teal--text font-weight-bold darken--4 teal lighten-5 border-bottom-1">
                  {{ wallet.credit || "" }}
                </td>
                <td class="text-left  error--text font-weight-bold darken--4 error lighten-5 border-bottom-1">
                  {{ wallet.debit || "" }}
                </td>
              </tr>
            </tbody>
            <tfoot v-else>
              <tr>
                <td colspan="5"><strong>No wallet entries.</strong></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <BaseModal :title="'Wallet Topup'" :modalId="'walletModal'">
      <WalletTopup
        @topupsuccess="getWalletEntries"
        :user_id="user_id"
      ></WalletTopup>
    </BaseModal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import WalletTopup from "../../components/payments/modals/WalletTopup.vue";
export default {
  name: "Wallet",
  components: {
    WalletTopup,
  },
  props: {
    user_id: {
      type: String,
    },
  },

  computed: {
    ...mapState("payment", ["wallet_entries"]),
    ...mapGetters('writers', ['wallet_balance'])
   
  },

  methods: {
    ...mapActions("payment", ["_getWalletEntries"]),
    async getWalletEntries() {
      await this._getWalletEntries(this.user_id);
    },
  },

  async mounted() {
    this.getWalletEntries();
  },
};
</script>
